.table-primary {
    & thead {
        @apply bg-gray-600 text-white;
        & td {
            @apply p-1;
        }
    }

    & tbody {
        & tr:nth-child(2n) {
            @apply bg-gray-100;
        }
        & tr:hover {
            @apply bg-gray-300;
        }
        & tr td {
            @apply p-1;
        }
    }
}

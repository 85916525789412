.ui-predicate__main {
  & .ui-predicate__row--compound > .ui-predicate__row:not(.ui-predicate__row--comparison) {
    .ui-predicate__col {
        @apply w-32;
    }
    .ui-predicate__options {
      display: none;
    }
  }
  select,
  input:not([type='checkbox']) {
    @apply w-full appearance-none rounded border border-gray-300 py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none;
  }
  .ui-predicate__row {
    @apply flex flex-row items-center;
  }
  .ui-predicate__row:not(.ui-predicate__row:nth-of-type(1)) {
    @apply mt-4;
  }
  .ui-predicate__row--compound {
    @apply mx-auto;
  }
  .ui-predicate__targets {
    @apply w-72;
  }
  .ui-predicate__operators {
    @apply w-24;
  }
  .ui-predicate__arguments {
    @apply w-32;
  }
  .ui-predicate__option:first-child {
    @apply mx-2;
  }
  .ui-predicate__option button {
    @apply w-full items-center rounded-md border border-transparent bg-accent-200 px-0 py-2 text-center text-sm font-medium text-white shadow-sm transition-colors duration-150 ease-out focus:outline-none focus:ring-2 focus:ring-accent-200 focus:ring-offset-2 disabled:bg-gray-300 sm:w-fit sm:px-4;
  }
}

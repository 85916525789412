@import './mixin.scss';

.bic-footer {
  font-size: rem(15);

  h3 {
    font-size: rem(15);
  }

  .footer-logo {
    width: rem(103);
    img {
      object-fit: cover;
    }
  }

  .footer-dropdown-btn {
    @include selection();
  }
}
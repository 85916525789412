@import './mixin.scss';
@import './variables.scss';

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  //-webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  padding-right: 0.5rem;
}

.input:not(.input-checkbox, select):read-only {
  background-color: rgba($color_disabled, 0.3);
}

.container {
  max-width: rem(1180);
  width: 100%;
  margin: 0 auto;
}

.font-color-bic {
  color: $color_primary;
}

.font-extralight {
  font-weight: $fw-extraLight;
}

.btn-primary {
  background-color: $color_primary;
  padding: rem(12) rem(40);
  border: 0;
  border-radius: rem(30);
  color: $color_white;
  font-weight: bold;
  line-height: 1.2;
  transition: all .3s;
  min-height: rem(42);

  &:hover {
    background: $color_dark
  }

  &:disabled,
  &.disabled {
    background-color: $color_disabled;
  }

  svg {
    width: rem(18);
  }
}

.btn-secondary {
  background-color: $color_white;
  border: $color_dark solid 1px;
  color: $color_dark;
  padding: rem(12) rem(40);
  border-radius: rem(30);
  font-weight: bold;
  line-height: 1.2;
  transition: all .3s;
  min-height: rem(42);

  &:hover {
    border-color: $color_primary;
    color: $color_primary;
  }

  &:disabled,
  &.disabled {
    border-color: $color_disabled;
    color: $color_disabled;
    cursor: default;
  }
}

.iconContainer {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: rem(20);
  line-height: 1;
  z-index: 1;

  > svg {
    flex-shrink: 0;
    width: 1em;
    height: 1em;
    font-size: 1em;
    fill: none;
    stroke: currentColor;
  }
}

input[type="checkbox"], 
input[type="radio"] {
  &:focus {
    outline-offset: 1px;
    --tw-ring-color: #D97D0D;
  }
}

.header-wrapper {
  @media (max-width: 767px) {
    box-shadow: 0px 2px 10px 0px rgba(41, 41, 41, 0.08);
    padding-bottom: rem(10);
    z-index: 3;
  }
}

.breadcrumb {
  margin: rem(30) 0;

  .separator {
    transform: rotate(-90deg);
    width: rem(13);
    margin: 0 rem(7);
  }
}

.border-color-gray {
  border-color: $color_disabled;
}

.btn-popover {
  color: $color_primary;
  display: flex;
  align-items: center;

  &:hover {
    color: $color_dark;
  }

  &:after {
    content: '';
    background-image: url('/images/icon/chevron-yellow.svg');
    background-repeat: no-repeat;
    background-size: cover;
    width: rem(11);
    height: rem(6);
    transform: rotate(0);
    transition: all .3s;
    margin-left: rem(10);
  }

  &:hover:after {
    background-image: url('/images/icon/chevron.svg');
  }

  &.popover-open {
    &:after {
      transform: rotate(180deg);
    }
  }
}

.btn-popover-disabled {
  color: $color_disabled;
  display: flex;
  align-items: center;
  &:hover {
    color: $color_disabled;
  }
  &:after {
    content: '';
    background-image: url('/images/icon/chevron-disabled.svg');
    background-repeat: no-repeat;
    background-size: cover;
    width: rem(11);
    height: rem(6);
    transform: rotate(0);
    transition: all .3s;
    margin-left: rem(10);
  }
  &:hover:after {
    background-image: url('/images/icon/chevron-disabled.svg');
  }
}

.popup-open {
  transform: rotate(180deg);
}
@import './variables';

.filters-label {
  color: $color_dark;
  font-weight: bold;
  font-size: rem(24);
  height: rem(50);
  border: $color_primary solid 2px;
  display: block;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    border: 0;
    border-bottom: $color_primary solid 2px;
    font-size: rem(26);
    height: auto;
    cursor: default;
    text-align: left;
    justify-content: flex-start;
  }
}

.filters-icon {
  width: 30px;
}

.filter-title {
  background-color: $color_light-blue;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 rem(20);
  height: rem(50);
  font-weight: $fw-semiBold;

  &-text {
    color: $color_dark;
  }
}

.filter-facets {
  @media (max-width: 767px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: $color_white;
    padding: rem(60) rem(20) rem(20);
    overflow-y: auto;
    z-index: 20;
  }
}

body.filter_open {
  overflow-y: hidden;
  width: 100%;
}

.close-btn {
  width: rem(30);
  position: absolute;
  top: rem(20);
  right: rem(10);
}
@import '../../../frontend/bic/styles/mixin.scss';
@import '../../../frontend/bic/styles/variables.scss';

.cart-table {
    th:not(:first-child) {
      width: 15%;
      text-align: center;
    }

    &__header {
        padding: 5px;
        background-color: #F6FAFF;
        & th:first-child {
          padding: 9px 10px;
        }
        &-action {
          width: 8%;
        }
        &-available-quantity {
          width: 8%;
        }
        &-quantity {
          width: 8%;
        }
        &-price {
          padding-right: rem(15);
        }
        &-total-price {
          padding-right: rem(15);
        }
        & > tr > th {
            text-align: start;
            font-weight: normal;
        }
    }
    & tbody {
        .td-details__availability {
          font-size: rem(12);
        }

        .p-1_text {
          vertical-align: top;
          padding-top: 1.75rem;
          padding-left: rem(15);
          padding-right: rem(15);

          @media (min-width: 1024px) {
            padding-left: 0;
          }
        }

        .input-primary {
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          input[type=number] {
            -moz-appearance: textfield;
          }
        }
    }

    .line-item {
      border-bottom: 1px solid #F6FAFF;
      vertical-align: top;

      > td:not(:first-child) {
        @media (min-width: 1024px) {
          width: 15%;
        }
      }
    }

    .inner-table {
      tbody {
        border: 0px;
      }
      border: 0px;
      td {
        padding: 25px 20px 30px 0;
        vertical-align: top;
      }
    }

    .td-line-item__image {
      padding-right: rem(20);
      width: rem(80);

      img {
        width: 100%;
        height: rem(60);
        margin-top: rem(5);
      }
    }

    .td__name {
      font-size: rem(18);
      font-weight: bold;
      cursor: pointer;

      &:hover {
        color: $color_primary;
      }
    }

    .td-details__sku {
      font-size: rem(15);
      font-weight: $fw-light;

      label {
        font-weight: $fw-light;
      }
    }

    .product-qty-wrapper {
      margin-top: rem(25);
    }

    .price {
      font-size: rem(18);
      font-weight: bold;

      &.discounted {
        margin-top: rem(-10);
      }
    }

    .dynamic-cart-item {
      .price {
        &.discounted {
          margin-top: rem(-15);

          @media (min-width: 1024px) {
            + .btns {
              padding-top: rem(30);
            }
          }
        }
      }

      .btns {
        margin-top: rem(-5);

        @media (min-width:1024px) {
          padding-top: rem(30);
        }
      }

      &__input {
        border: 0;
        border-bottom: solid 2px $color_primary;
        border-radius: 0;
        box-shadow: none;
        padding-top: rem(16);
      }
    }
}

@media (max-width: 1023px) {
  .cart-table {
    display: block;
    thead {
      display: none;
    }

    .line-item {
      border-bottom: solid 1px #e3dbdb;
      display: flex;
      flex-wrap: wrap;

      > td:first-child { width: 100%;}

      .product-qty-wrapper {
        margin-top: rem(5);
      }

      > .price {
        padding-top: rem(15);
      }
    }

    tbody,
    tr, td {
      display: block;
    }

    .inner-table {
        tbody {
        display: flex;
      }

      .td-line-item__details {
        padding-bottom: 0;
      }
    }
  }

  .td-line-item__image {
    flex-shrink: 0;
  }

  .cart-table__header {
    display: none;
  }

  .dynamic-cart-item {
    padding-bottom: rem(30);

    button.button {
      width: rem(60);
      display: inline-flex;
      justify-content: center;
    }

    &.line-item > .price {
      padding-top: 0;
    }

    .td-details__other-buttons {
      width: 100%;
    }

    &.line-item .product-qty-wrapper {
      margin-top: rem(-15);
    }
  }
}
.highlight {
    animation: hightlight 2s cubic-bezier(0.4, 0, 0.6, 1);
    animation-iteration-count: 4;
  }
  @keyframes hightlight {
    0%,
    100% {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.3);
    }
    50% {
      opacity: 0.5;
      background-color: initial;
    }
  }

.shake {
    animation: bellshake 0.5s cubic-bezier(.36,.07,.19,.97);
    transform-origin: top;
    animation-iteration-count: 10;
  }
  @keyframes bellshake {
    0% { transform: rotate(0); }
    15% { transform: rotate(5deg); }
    30% { transform: rotate(-5deg); }
    45% { transform: rotate(4deg); }
    60% { transform: rotate(-4deg); }
    75% { transform: rotate(2deg); }
    85% { transform: rotate(-2deg); }
    92% { transform: rotate(1deg); }
    100% { transform: rotate(0); }
  }

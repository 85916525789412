@import './variables.scss';
@import './mixin.scss';

h1 {
  font-size: rem(45);
  line-height: rem(55);
  font-weight: bold;
}

h2 {
  font-size: rem(39);
  line-height: rem(49);
  font-weight: $fw-extraLight;
}

h3 {
  font-size: rem(30);
  line-height: 1.4;
  font-weight: bold;
}
.dynamic-cart {
  &-item {
    &:focus-within &__search {
      display: block;
    }
    &__input {
      &:focus + &__search {
        display: block;
      }

      flex-basis: 100%;
    }
    &__input-wrapper {
      flex-basis: 100%;
      position: relative;
    }
    &__selected-item {
      flex-basis: 100%;
    }
    &__quantity {
      width: 80px;
    }
    &__input-loader {
      position: absolute;
      right: 15px;
      top: 11px;
    }
    &__search {
      width: 99%;
      @apply absolute z-40 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm;
      &-item {
        @apply relative cursor-default select-none px-5 hover:bg-gray-100;
      }
    }
  }
  &__add-item {
    @apply ml-2 items-center rounded-md border border-transparent bg-accent-400 px-2 py-2 text-center text-sm font-medium text-white shadow-sm transition-colors duration-150 ease-out focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2 disabled:bg-gray-300;
    align-self: center;
  }
}

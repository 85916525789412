.line-item {
    position: relative;
    &__name {
        @apply cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap font-medium text-gray-700 hover:text-gray-800 dark:text-light-100;
        max-width: 200px;
    }
    &__aq {
        width: 30px;
    }

    &.disabled::after {
        position: absolute;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(156, 163, 175, 0.455)
    }
    &__loading {
        position: absolute;
        top: 15px;
        right: 10px;
    }
}

.cart-wrapper {
  @media (min-width: 1024px) {
    //display: flex;
    gap: rem(40);
    justify-content: space-between;
  }

  .btn-remove {
    background: url('/images/icon/remove.svg') no-repeat 0 center;
    background-size: rem(15) rem(15);
    padding-left: rem(20);

    &:hover {
      background-image: url('/images/icon/remove-yellow.svg');
    }
  }

  .td-line-item__details {
    button {
      &:hover {
        color: $color_primary;
      }
    }
  }

  .btn-search {
    position: absolute;
    left: 0;
    top: rem(18);
  }

  .dynamic-cart-item {
    &__input {
      padding-left: rem(30);
    }
  }
}

.cart-title {
  color: $color_black;
  font-size: rem(38);
}

.cart-summary {
  &-wrapper {
    background-color: $color_light-blue;
    padding: rem(25);
  }

  @media (min-width: 1024px) {
    width: 30%;
    flex-shrink: 0;
  }

  @media (min-width: 1440px) {
    width: rem(360);
  }

  &-title {
    font-size: rem(18);
    font-weight: bold;
    line-height: 1;
  }

  .tax-note {
    margin-top: 0 !important;
    font-size: rem(14);
  }

  .btn-checkout {
    font-size: rem(17);
  }

  .btn-request-quote {
    color: $color_primary;

    &:hover {
      color: $color_dark;
    }
  }

  .btn-discount {
    padding: 0;
  }
}
.product-qty {
  &-wrapper {
    position: relative;
    width: rem(70);
  }

  &-input {
    background-color: $color_light-blue;
    border-radius: 50px;
    border: 0;
    font-weight: $fw-light;
    width: rem(88);
    height: rem(42);
    padding-right: rem(30);
    padding-left: rem(12);

    &:focus {
      border: 0;
      outline: 0;
      box-shadow: $shadow-base;
    }
  }

  &-btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: rem(40);
    padding: rem(3) 0;
  }

  &-btn {
    height: 50%;
    width: rem(12);

    &-increase {
      transform: rotate(180deg);
    }

    &:disabled {
      opacity: .5;
    }
  }
}

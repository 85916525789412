.product-detail-gallery  {
  @media (min-width: 1024px) {
    width: rem(540);
  }

  @media (min-width: 1280px) {
    width: rem(660);
  }

  img {
    position: relative;
    object-fit: contain;
    z-index: 2;
  }

  .slider__container {
    padding-bottom: 0;
  }

  .slider__container--with-thumbs {
    display: block;
    width: 100%;

    @media (min-width: 768px) {
      gap: rem(0);
      display: grid;
    }

    @media (min-width: 1280px) {
      gap: rem(20)
    }

    .slider__thumbs {
      display: none;
      width: rem(60);

      @media (min-width: 768px) {
        display: block;
      }
    }
  }

  .slider__slides {
    padding: 0;

    @media (min-width: 768px) {
      width: rem(580);
      height: rem(580);
    }

    @media (min-width: 1024px) {
      width: rem(480);
      height: rem(480);
    }

    @media (min-width: 1280px) {
      width: rem(560);
      height: rem(560);
    }

    img {
      height: 100%;
      max-width: 100%;

      @media (max-width: 767px) {
        position: absolute;
        top: rem(10);
        left: rem(0);
        right: 0;
        margin: auto;
      }
    }
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:before {
      content: '';
      background: $color_white;
      width: calc(100% - rem(20px));
      height: calc(100% - rem(20px));
      position: absolute;
      left: rem(10);
      top: rem(10);
      z-index: 0;
    }

    @media (max-width: 767px) {
      padding: 100% rem(10) rem(10)
    }
  }

  .slider__thumbs {
    @media (min-width: 768px) {
      height: rem(530);
    }

    @media (min-width: 1024px) {
      height: rem(440);
    }

    @media (min-width: 1280px) {
      height: rem(530);
    }
    
    img {
      height: rem(60);
      width: 100%;
      border: transparent solid 1px;
    }

    .swiper-slide {
      border: transparent solid 1px;
      padding-bottom: 0;
    }

    .swiper-slide-thumb-active {
      border: $color_primary solid 1px;
    }
  }

  .swiper-pagination {
    display: block;

    @media (min-width: 768px) {
      display: none;
    }
  }

  .swiper-button-prev, 
  .swiper-button-next {
    background-color: transparent;
    transform: rotate(90deg);
    left: 0;
    right: 0;
    margin: 0 auto;

    &:hover {
      background-color: transparent;
    }
  }

  .swiper-button-prev {
    top: 0;
  }

  .swiper-button-next {
    bottom: 0;
    top: auto;
  }
}

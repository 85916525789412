.categories-page {
  @media (min-width: 768px) {
    gap: rem(20);
  }

  @media (min-width: 1024px) {
    gap: rem(40);
  }

  .filters {
    @media (min-width: 768px) {
      flex: 1;
      max-width: 33%;
    }
  }

  .product-lists {
    @media (min-width: 768px) {
      flex: 2
    }

    @media (min-width: 1440px) {
      flex: 3
    }
  }

  .number-of-items {
    font-weight: $fw-light;
  }
}

.list-items {
  @media (min-width: 480px) {
    gap: rem(20);
    display: grid;
    grid-template-columns: repeat(2, 2fr);
  }

  @media (min-width: 1024px) {
    gap: rem(40);
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(3, 3fr);
  }
}

.product-item {
  color: $color_black;

  @media (min-width: 480px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-name {
    @include text-clamp(1);
  }

  &-img img {
    height: rem(260);
  }

  &-price {
    font-size: rem(24);
    font-weight: bold;
  }

  &-available-qty {
    color: $color_dark;
    font-weight: $fw-light;
    font-size: rem(14);
  }
}

.old-price {
  text-decoration: line-through;
  color: $color_disabled;
  font-weight: normal;
}

.categories-pagination {
  margin: rem(60) 0 rem(100);
}

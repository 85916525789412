@import './variables';
@import 'product-gallery';
@import 'product-qty';
@import 'tabs';

.product-detail-page  {
  .bic-tabs {
    margin-top: rem(50);
    margin-bottom: rem(50);

    @media (min-width: 1024px) {
      margin-top: rem(90);
      margin-bottom: rem(100);
    }

    .tab-product-characteristics {
      p {
        padding-bottom: rem(10);
      }
    }

    .tab-product-description,
    .tab-product-description p {
      line-height: 2;
    }
  }
}

.product-details {
  @media (min-width: 1024px) {
    display: flex;
  }
}

.product-info {
  @media (min-width: 768px) {
    margin-top: rem(50);
  }

  @media (min-width: 1024px) {
    width: calc(100% - rem(540));
    padding-left: rem(20);
    margin-top: rem(-7);
  }

  @media (min-width: 1280px) {
    width: calc(100% - rem(660));
    padding-left: rem(40);
  }
}

.product {  
  &-info {
    .btn-add-to-cart {
      width: 100%;

      @media (min-width: 640px) {
        max-width: rem(175);
      }
    }
  }

  &-brand {
    font-weight: $fw-semiBold;
  }

  &-name {
    font-size: rem(26);
    line-height: 1.4;
  }

  &-price {
    font-size: 1.35rem;
    font-weight: $fw-semiBold;
  }

  &-availabitity-qty {
    color: $color_black;
    font-weight: $fw-light;
    display: flex;
    align-items: flex-end;
    padding-bottom: rem(5);
    margin-left: rem(10);
  }

  &-status {
    padding-left: rem(25);
    position: relative;
    margin-top: rem(15);

    &:before {
      content: '';
      border-radius: 50%;
      width: rem(15);
      height: rem(15);
      display: block;
      position: absolute;
      left: 0;
      top: rem(4);
    }

    &.in-stock {
      &:before {
        background-color: #33D4A6;
      }
    }

    &.out-of-stock {
      &:before {
        background-color: red;
      }
    }
  }
}

.wishlist-popup {
  width: rem(320);

  .btn-primary {
    padding-left: rem(10);
    padding-right: rem(20);
    display: flex;
    margin: rem(10) auto 0;
  }

  .btn-create-wishlist {
    padding-left: rem(20);
    padding-right: rem(20);
  }
}

.download-link {
  font-weight: bold;

  a {
    position: relative;
    padding-left: rem(23);
    display: inline-block;

    &:before {
      content: '';
      background-image: url('/images/icon/download-icon.svg');
      background-repeat: no-repeat;
      width: rem(15);
      height: rem(16);
      display: block;
      position: absolute;
      left: 0;
      top: rem(6);
    }

    &:hover {
      color: $color_primary;
  
      &:before {
        background-image: url('/images/icon/download-yellow-icon.svg');
      }
    }
  }
}
.input {
    &-primary {
        @apply w-full appearance-none rounded border border-gray-300 py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none;
    }
    &-checkbox {
        @apply h-6 w-6 rounded border-gray-300 text-white focus:ring-accent-400 text-accent-400 disabled:bg-gray-300;
    }
    &:not(.input-checkbox,select):read-only {
        @apply bg-gray-300;
    }
    &:disabled {
        @apply bg-gray-300;
    }
}

.menu-open {
  overflow: hidden;
  width: 100%;
}

.left-100 {
  left: -100%;
}

.bicNavigationOpen {
  left: 0 !important;
  margin-left: rem(-26) !important;
  display: block !important;
}

.subNavWrapper {
  @media (min-width: 768px) {
    display: none;
    background: $color_white;
    box-shadow: 0px 2px 3px 0px rgba(41, 41, 41, 0.08) inset;
    border-bottom: 1px solid rgba(41, 41, 41, 0.1);
    left: -50vw;
    margin-left: 50%;
    position: absolute;
    top: 100%;
    width: 100vw;
    z-index: 2;
  }
}

.submenu {
  @media (min-width: 768px) {
    max-width: $layout-width;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
  }

  &-group {
    @media (min-width: 768px) {
      flex: 1;
      min-width: rem(200);
      padding-bottom: rem(50);
      padding-right: rem(16);
      padding-left: rem(16);
    }
  }
}

.subNavIsShowing {
  @media (max-width: 767px) {
    color: $color_white !important;
    padding-left: rem(16) !important;
  }

  @media (min-width: 768px) {
    border-bottom-color: $color_primary !important;
  }

  + .subNavWrapper {
    display: block;
    @media (max-width: 767px) {
      position: absolute;
      left: 0 !important; 
    }
  }
}

.subnav-heading {
  font-size: rem(16);
  padding: rem(10) 0;
  justify-content: space-between;
  align-items: center;
  color: $color_dark_2;

  @media (min-width: 768px) {
    font-size: rem(14);
  }
}

.subMenu-mobile {
  @media (max-width: 767px) {
    &.isShowing {
      .arrowBtn-level-0 img {
        transform: rotate(180deg);
      }
  
      + .sub-nav-level-0 {
        display: block;
      }
    }
  }
}

.arrowBtn-level-0 {
  padding-left: rem(30);

  img {
    transform: rotate(0);
    transition: all .3s;
  }
}

.sub-nav-level-0 {
  @media (max-width: 767px) {
    display: none;
  }

  li {
    @media (max-width: 767px) {

    }
  }

  a {
    color: $color_dark_2;
    font-weight: 300;
    padding: rem(10) 0;

    @media (min-width: 767px) {
      padding: rem(5) 0;
      font-size: rem(14);
    }
  }
}

.nav-item {
  display: block;

  button.active,
  > a.active {
    border-bottom-color: $color_primary !important;
  }
}

.hamburger {
  margin-left: rem(30);
  cursor: pointer;

  > span {
    width: rem(26);
    height: rem(2.5);
    background-color: $color_dark_2;
    border-radius: 2px;
    display: block;
    margin: rem(6) auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
}


.menuIsActive {
  .hamburger > span:nth-child(2){
    opacity: 0;
  }

  .hamburger > span:nth-child(1){
    transform: translateY(rem(7.5)) rotate(45deg);
  }

  .hamburger > span:nth-child(3){
    transform: translateY(rem(-9.5)) rotate(-45deg);
  }
}

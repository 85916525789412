@import '../styles/variables.scss';
@import '../styles/mixin.scss';

.swiper-wrapper {
  padding-bottom: rem(60);
}

.bic {
  .slider__container,
  .swiper,
  .swiper-img {
    height: 100%;
  }

  .swiper-slide {
    padding-bottom: rem(5);
  }

  .swiper-button {
    &-next,
    &-prev {
      background: $color_primary;
      color: $color_white;
      width: rem(45);
      height: rem(45);
      border-radius: 50%;
      transition: all .3s;

      &:after {
        font-size: rem(20);
      }

      &:hover {
        background: $color_dark;
      }

      &.swiper-button-disabled {
        pointer-events: auto;
        cursor: default;

        &:hover {
          background: $color_primary;
        }
      }
    }
  }

  .swiper-pagination-bullet {
    width: rem(14);
    height: rem(14);
    border: $color_primary solid 1px;
    background: none;
    opacity: 1;
  }

  .slider__bullet--active {
    background: $color_primary;
  }
}

.bic-carousel {
  .swiper-slide {
    .img {
      height: rem(260);
      padding: rem(15) 0;

      img {
        height: 100%;
      }
    }

    &-title  {
      font-size: rem(16);
      font-weight: normal;
      @include text-clamp(1)
    }
  }

  .title {
    position: relative;

    &:after {
      content: '';
      background: $color_primary;
      width: rem(80);
      height: 3px;
      display: block;
      margin: rem(20) auto 0;
    }
  }
}
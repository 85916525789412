/* BIC Style */
@font-face {
  font-family: "NotoSans";
  src: url("/fonts/NotoSans-Regular.woff2") format("woff2"),
  url("/fonts/NotoSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  unicode-range: U+20-29,U+2B-5E,U+60-7E,U+A3,U+A8,U+A9,U+AB,U+AE-B5,U+B7,U+B9-BE,U+C0-CF,U+D1-D7,U+D9-DD,U+E0-EF,U+F1-F7,U+F9-FD,U+FF,U+152,U+153,U+178,U+2C6,U+2DA,U+2DC,U+2013,U+2014,U+2018-201A,U+201C-201E,U+2022,U+2026,U+2039,U+203A,U+20AC,U+2122;
}

@font-face {
  font-family: "NotoSans";
  src: url("/fonts/NotoSans-Medium.woff2") format("woff2"),
  url("/fonts/NotoSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  unicode-range: U+20-29,U+2B-5E,U+60-7E,U+A3,U+A8,U+A9,U+AB,U+AE-B5,U+B7,U+B9-BE,U+C0-CF,U+D1-D7,U+D9-DD,U+E0-EF,U+F1-F7,U+F9-FD,U+FF,U+152,U+153,U+178,U+2C6,U+2DA,U+2DC,U+2013,U+2014,U+2018-201A,U+201C-201E,U+2022,U+2026,U+2039,U+203A,U+20AC,U+2122;
}

@font-face {
  font-family: "NotoSans";
  src: url("/fonts/NotoSans-SemiBold.woff2") format("woff2"),
  url("/fonts/NotoSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  unicode-range: U+20-29,U+2B-5E,U+60-7E,U+A3,U+A8,U+A9,U+AB,U+AE-B5,U+B7,U+B9-BE,U+C0-CF,U+D1-D7,U+D9-DD,U+E0-EF,U+F1-F7,U+F9-FD,U+FF,U+152,U+153,U+178,U+2C6,U+2DA,U+2DC,U+2013,U+2014,U+2018-201A,U+201C-201E,U+2022,U+2026,U+2039,U+203A,U+20AC,U+2122;
}

@font-face {
  font-family: "NotoSans";
  src: url("/fonts/NotoSans-Bold.woff") format("woff2"),
  url("/fonts/NotoSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  unicode-range: U+20-29,U+2B-5E,U+60-7E,U+A3,U+A8,U+A9,U+AB,U+AE-B5,U+B7,U+B9-BE,U+C0-CF,U+D1-D7,U+D9-DD,U+E0-EF,U+F1-F7,U+F9-FD,U+FF,U+152,U+153,U+178,U+2C6,U+2DA,U+2DC,U+2013,U+2014,U+2018-201A,U+201C-201E,U+2022,U+2026,U+2039,U+203A,U+20AC,U+2122;
}

@font-face {
  font-family: "NotoSans";
  src: url("/fonts/NotoSans-Light.woff") format("woff2"),
  url("/fonts/NotoSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  unicode-range: U+20-29,U+2B-5E,U+60-7E,U+A3,U+A8,U+A9,U+AB,U+AE-B5,U+B7,U+B9-BE,U+C0-CF,U+D1-D7,U+D9-DD,U+E0-EF,U+F1-F7,U+F9-FD,U+FF,U+152,U+153,U+178,U+2C6,U+2DA,U+2DC,U+2013,U+2014,U+2018-201A,U+201C-201E,U+2022,U+2026,U+2039,U+203A,U+20AC,U+2122;
}

@font-face {
  font-family: "NotoSans";
  src: url("/fonts/NotoSans-ExtraLight.woff") format("woff2"),
  url("/fonts/NotoSans-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  unicode-range: U+20-29,U+2B-5E,U+60-7E,U+A3,U+A8,U+A9,U+AB,U+AE-B5,U+B7,U+B9-BE,U+C0-CF,U+D1-D7,U+D9-DD,U+E0-EF,U+F1-F7,U+F9-FD,U+FF,U+152,U+153,U+178,U+2C6,U+2DA,U+2DC,U+2013,U+2014,U+2018-201A,U+201C-201E,U+2022,U+2026,U+2039,U+203A,U+20AC,U+2122;
}

body {
  font-family: NotoSans, Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: 20px;
  font-size: 15px;
  color: #000;
}

img {
  max-width: 100%;
}

h1 {
  font-weight: bold;
  font-size: 38px;
  line-height: 1;
}

h2 {
  font-weight: 200;
  font-size: 39px;
  line-height: 49px;
}
.bic-tabs {
  .tab-title {
    background-color: $color_light-blue;
    position: relative;
    padding: rem(14) rem(20);
    cursor: pointer;

    @media (min-width: 1024px) {
      background-color: transparent;
      border-bottom: 4px solid transparent;
      flex: 1;
      text-align: center;
      &:hover {
        color: $color_primary;
      }
    }

    &.is-open {
      @media (min-width: 1024px) {
        border-bottom-color: $color_primary;
      }

      .tab-title-icon:after {
        display: none;
      }

      + .tab-content {
        display: block;
      }
    }
  }

  .tab-title-icon {
    &:before {
      content: '';
      width: rem(16);
      height: rem(2);
      display: block;
      background-color: $color_dark;
      position: absolute;
      top: 0;
      bottom: 0;
      right: rem(15);
      margin: auto;
    }

    &:after {
      content: '';
      width: rem(16);
      height: rem(2);
      display: block;
      background-color: $color_dark;
      position: absolute;
      top: 0;
      bottom: 0;
      right: rem(15);
      margin: auto;
      transform: rotate(90deg);
    }
  }

  .tab-content {
    padding: rem(30) 0;
    display: none;

    @media (min-width: 1024px) {
      background-color: $color_light-blue;
      padding: rem(40) rem(50);
    }
  }
}

.table-container {
  max-width: 100%;
  margin: auto;
  overflow-x: auto;

  table {
    position: relative;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: none;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    white-space: nowrap;

    @media (min-width: 768px) {
      table-layout: fixed;
    }

    * {
        border: none;
      }
    
      td, th {
        text-align: center;
        width: 25%;
      }

    thead tr {
      font-size: rem(16);
      font-weight: 500;
      text-align: left;

      th {
          background: #edf2f7;
          padding: 0.75rem 1.5rem;
          vertical-align: middle;
          font-size: rem(15);
      }
    }

    tbody {
      tr:nth-child(odd) td {
          background: $color_white;
      }

      tr:nth-child(even) td {
          background: #edf2f7;
      }
      td {
          padding: rem(10);
          vertical-align: middle;
          font-weight: normal;
      }
    }
  
    tr>th:first-child,tr>td:first-child {
      background-color: #edf2f7;
      position: sticky;
      left: 0;
      text-align: left;
      font-weight: bold;
    }

    .merge-th {
      @media (min-width:768px) {
        width: 50%;
      }
    }
  }
}
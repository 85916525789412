@import './variables';

.pagination {
  display: flex;
  justify-content: center;

  a {
    display: inline-flex;
    line-height: rem(30);
    padding: 0 rem(15);
    font-size: rem(18);
    
    @media (min-width: 768px) {
      padding: 0 rem(22);
    }

    &:hover {
      color: $color_primary;
    }
  }

  .page-prev {
    padding-right: rem(15);
    @media (min-width: 768px) {
      padding-right: rem(22);
    }
  }

  .page-next {
    padding-left: rem(15);
    @media (min-width: 768px) {
      padding-left: rem(22);
    }
  }

  .page-item {
    &.active {
      color: $color_primary;
      font-weight: bold;
    }

    &.disabled {
      .page-link {
        cursor: default;
      }

      .link-prev,
      .link-next {
        background-color: $color_disabled;
      }
    }
  }

  .link-prev,
  .link-next {
    background-color: $color_primary;
    width: rem(30);
    height: rem(30);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: $color_white;
    border-radius: 50%;
    font-size: 0;
    transition: all .3s;
    padding: 0;

    &:hover {
      background-color: $color_dark;
    }

    &.disabled {
      background-color: $color_disabled;
    }

    &:before {
      content: '';
      background: url('/images/icon/chevron-white.svg') no-repeat;
      background-position: center;
      background-size: 15px 20px;
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .link-prev {
    &:before {
      transform: rotate(90deg);
    }
  }

  .link-next {
    &:before {
      transform: rotate(-90deg);
    }
  }
}